<template>
  <div ref="projectlist">
    <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TREETABLE :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TREETABLE>

    <add-v-type @close='onCallbackClose' :data='vtdata' v-if="isShowAdd"></add-v-type>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TREETABLE from '@/components/treeTable' // 树表格
import addVType from './components/addVType'
import { getVType, getVTypeDetail, delVFile } from '@/api/monitor'
export default {
  name: 'UnitName',
  components: {
    SEARCH,
    TABS,
    TREETABLE,
    addVType,
  },
  data() {
    return {
      searchArr: [
        {
          name: '分类名称',
          type: 'input',
          options: [],
          key: 'name',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '新增分类',
          func: 'open',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'name',
          label: '单位名称',
          width: '1000',
        },
        {
          prop: 'sort',
          label: '排序',
          width: '300',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: 'edit',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'remove',
        },
      ], // 数据-操作列表
      formData: {
        name: '',
      },
      vtdata: {},
      isShowAdd: false,
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      getVType(this.formData).then((res) => {
        const { result } = res
        this.dataArr = result
        this.deepData(this.dataArr)
      })
    },
    deepData: function(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].children && list[i].children.length) this.deepData(list[i].children)
        list[i].buttons = this.butnArr
      }
    },

    open: function() {
      this.vtdata = {}
      this.isShowAdd = true
    },

    edit: function({ id }) {
      getVTypeDetail(id).then(res => {
        const { result } = res
        this.vtdata = result.info
        this.isShowAdd = true
      })
    },
    remove: function({ id }) {
      delVFile(id).then(res => {
        this.$toast(res.message)

        if (res.status === 200) {
          this._loadData()
        }
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { name } = data
      this.formData.name = name
      this._loadData()
    },
    // 收到 tabs 回调
    onCallbackTab: function({ item, index }) {
      this[item.func](item)
    },
    onCallbackClose: function(event) {
      this.isShowAdd = false
      event && this._loadData()
    },
    // 收到 表格 内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },
  },
}
</script>

<style lang="stylus" scoped></style>
